import React from 'react';
import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { Link } from 'gatsby';
import { css } from 'twin.macro';

const NotFoundPage = () => (
  <div>
    <SEO title="404: Not found" url={'/'} />
    <Container>
      <div className="py-16 flex flex-col sm:flex-row flex-wrap sm:space-x-16 justify-center">
        <div>
          <Heading
            variant="h2"
            className="text-5xl sm:text-8xl"
            style={{
              fontSize: '3rem',
              lineHeight: '1',
            }}
          >
            404
          </Heading>
        </div>
        <div>
          <Heading variant="h5">La pagina non è stata trovata</Heading>
          <Link to="/" className="font-bold text-primary-500">
            Pagina di destinazione
          </Link>
        </div>
      </div>
    </Container>
  </div>
);

export default NotFoundPage;
